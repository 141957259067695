import useInterval from 'use-interval';
import qrCode from './qrcode.png';
import './bluescreen.css';
import {useState, useRef, useEffect} from "react";
import alright from './ALRIGHT.EXE.wav';

// todo secret code
const percentages = [
    84,
    72,
    69,
    32,
    77,
    85,
    83,
    73,
    67,
    32,
    73,
    83,
    32,
    84,
    72,
    69,
    32,
    77,
    69,
    83,
    83,
    65,
    71,
    69
];

export const Bluescreen = ({setShowBluescreen}) => {
    const [percentage, setPercentage] = useState(0);
    const [index, setIndex] = useState(0);
    const soundRef = useRef();

    useInterval(() => {
        const newIndex = index + 1;
        if(newIndex === percentages.length){
            setShowBluescreen(false);
            return;
        }
        setIndex(newIndex);
        setPercentage(percentages[newIndex]);
    }, 2000);

    useEffect(() => {
        soundRef.current.volume = 0.3;
        soundRef.current.play();
    }, [soundRef]);

    return (
        <div className='bluescreen'>
            <div className='bluescreen-content'>
                <div className='bluescreen-smiley'>
                    :3
                </div>
                <div className='bluescreen-big-text'>
                    Invalid Gateway ran into a problem and needs to restart. We're just collecting some error info, and then we'll restart for you.
                </div>
                <div className='bluescreen-percentage'>
                    {percentage}% complete
                </div>
                <div className='bluescreen-qr-and-error'>
                    <img className='bluescreen-qr-code' src={qrCode} alt='wouldnt you like to know UwU' />
                    <div className='bluescreen-error'>
                        <div className='bluescreen-error-title'>
                            For more information about this issue and possible fixes, visit http://invalidgateway.com/#/secret
                        </div>
                        <div className='bluescreen-error-message'>
                            If you call a support person, give them this info:
                            <br />
                            <br />
                            Stop code: TOO_MANY_TRICKS
                        </div>
                    </div>
                </div>
            </div>
            <audio src={alright} ref={soundRef} />
        </div>
    );
};